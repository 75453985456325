import React, { useState } from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import x from "../assets/x-logo.svg";

const Header = () => {
  const [sideBarOpen, setSidebarOpen] = useState(false);
  const [asideBarOpen, setAsidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sideBarOpen);
  };

  const toggleAsidebar = () => {
    setAsidebarOpen(!asideBarOpen);
  };

  return (
    <div
      className={`${sideBarOpen ? "aside-open" : ""} ${
        asideBarOpen ? "aside-right-open" : ""
      }`}
    >
      <aside className="sigma_aside">
        <div className="sigma_close aside-trigger" onClick={toggleSidebar}>
          <span />
          <span />
        </div>
        <div className="sigma_logo-wrapper" onClick={toggleSidebar}>
          <Link className="sigma_logo" to="/">
            <img
              src={logo}
              alt="logo"
              width={192}
              height={90}
              style={{ position: "relative", left: "10%" }}
            />
          </Link>
        </div>
        <ul className="navbar-nav">
          <li className="menu-item" onClick={toggleSidebar}>
            <Link to="/">Home</Link>
          </li>
          <li className="menu-item" onClick={toggleSidebar}>
            <Link to="/about">About Us</Link>
          </li>
          <li className="menu-item" onClick={toggleSidebar}>
            <Link to="/services">Services</Link>
          </li>
          <li className="menu-item" onClick={toggleSidebar}>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </aside>
      <div
        className="sigma_aside-overlay aside-trigger"
        onClick={toggleSidebar}
      />
      <aside className="sigma_aside sigma_aside-desktop">
        <div className="sidebar">
          <div className="sidebar-widget widget-logo">
            <img src={logo} className="mb-30" alt="img" />
            <p>
              Revolutionize your importation process with us, offering faster,
              cheaper and stressless solutions. We streamline every step,
              ensuring efficiency and cost-effectiveness. Say goodbye to import
              headaches and embrace seamless transactions. Explore our services
              today for a hassle-free importation experience!
            </p>
          </div>

          <div className="sidebar-widget">
            <h5 className="widget-title">Follow Us</h5>
            <div className="sigma_post-share">
              <ul className="sigma_sm square light">
                <li>
                  <a href="#/">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="#/">
                    <i className="fab fa-linkedin-in" />
                  </a>
                </li>
                <li>
                  <a href="#/">
                    <img src={x} alt="x logo" />
                  </a>
                </li>
                <li>
                  <a href="#/">
                    <i className="fab fa-youtube" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Social Media End */}
        </div>
      </aside>
      <div
        className="sigma_aside-overlay aside-trigger-right"
        onClick={toggleAsidebar}
      />
      <header className="sigma_header style-5 can-sticky">
        <div className="sigma_header-top dark-bg d-none d-md-block">
          <div className="container">
            <div className="sigma_header-top-inner">
              <div className="sigma_header-top-links">
                <ul className="sigma_header-top-nav">
                  <li>
                    <a href="mailto:info@importwithapex.com">
                      <i className="fal fa-envelope" />
                      info@importwithapex.com
                    </a>
                  </li>
                  <li>
                    <a href="#/">
                      <i className="fal fa-map-marker-alt" />
                      Plot 1, Maruwa Bus Stop, 128 Remi Olowude St, Lekki Phase
                      1, Lagos
                    </a>
                  </li>
                </ul>
              </div>
              <div className="sigma_header-top-contacts">
                <ul className="sigma_header-top-nav">
                  <li>
                    <a href="#/">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#/">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        style={{ width: "14", height: "14" }}
                      >
                        <path
                          fill="white"
                          d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="#/">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="sigma_header-middle">
          <div className="container">
            <div className="navbar">
              <div className="sigma_logo-wrapper">
                <Link className="sigma_logo" to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="d-none d-lg-flex align-items-center">
                <div className="sigma_header-top-links important-links">
                  <ul className="sigma_header-top-nav">
                    <li>
                      <a href="#/">
                        <i className="fal fa-map-marker-alt" />
                        <div>
                          <p>Our Address</p>
                          <p>
                            <b>Lagos, Nigeria</b>
                          </p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="tel:+2348147207713">
                        <i className="fal fa-phone" />
                        <div>
                          <p>Call Us</p>
                          <p>
                            <b>+2348147207713</b>
                          </p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <Link to="/contact" className="sigma_btn-custom ml-5">
                  Get a quote
                </Link>
              </div>
              <div
                className="sigma_header-controls style-2 d-block d-lg-none"
                onClick={toggleSidebar}
              >
                <ul className="sigma_header-controls-inner">
                  <li className="aside-toggle aside-trigger">
                    <span />
                    <span />
                    <span />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="sigma_header-bottom">
          <div className="container">
            <div className="navbar">
              <ul className="navbar-nav">
                <li className="menu-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="menu-item">
                  <Link to="/about">About Us</Link>
                </li>
                <li className="menu-item">
                  <Link to="/services">Services</Link>
                </li>
                <li className="menu-item">
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
              <div className="sigma_header-controls style-2">
                <ul className="sigma_header-controls-inner">
                  <li
                    className="aside-toggle aside-trigger-right desktop-toggler"
                    onClick={toggleAsidebar}
                  >
                    <span />
                    <span />
                    <span />
                  </li>
                  <li className="aside-toggle aside-trigger">
                    <span />
                    <span />
                    <span />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
