import React from "react";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      <div
        className="sigma_subheader dark-overlay"
        style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1>Services</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/services">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Services
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img src="assets/img/about2.jpg" alt="img" />
            </div>
            <div className="col-lg-6">
              <div className="mr-lg-30">
                <div className="sigma_counter style-2 no-border">
                  <b className="counter" data-from={0} data-to={75}>
                    5
                  </b>
                  <div className="sigma_counter-content">
                    <span>years</span>
                    <span>Working</span>
                    <span>Experience</span>
                  </div>
                </div>
                <p>
                  With five years of invaluable experience, Apex Product Import
                  Services is a trusted leader in the import and export
                  industry, offering a comprehensive suite of services tailored
                  to meet the diverse needs of businesses worldwide. From
                  compliance audits and risk reports to customs clearance and
                  logistics management, our strategic approach is founded on
                  innovation, efficiency, and a relentless commitment to
                  customer satisfaction. Our dynamic strategy encompasses
                  proactive risk management, strategic partnership development,
                  and leveraging cutting-edge technologies to optimize supply
                  chain processes and drive sustainable growth for our clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="subtitle">
              <span /> Popular Services <span />
            </h6>
            <h4 className="title">Our Featured Services</h4>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="sigma_service style-1">
                <div className="sigma_service-body">
                  <h5>Compliance Audits and Risk Reports</h5>
                  <p>
                    We conduct thorough compliance audits to evaluate adherence
                    to import regulations and identify potential risks. Through
                    comprehensive risk reports, businesses gain insights into
                    areas requiring improvement and mitigate compliance-related
                    challenges.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_service style-1">
                <div className="sigma_service-body">
                  <h5>Compliance Program</h5>
                  <p>
                    Developing and implementing robust compliance programs
                    tailored to specific industry regulations ensures adherence
                    to legal requirements, minimizes regulatory risks, and
                    fosters a culture of compliance within organizations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_service style-1">
                <div className="sigma_service-body">
                  <h5>Product Classification</h5>
                  <p>
                    Accurate product classification is essential for determining
                    applicable duties, taxes and regulatory requirements. We
                    employ expertise in product classification to ensure proper
                    categorization, reducing the risk of penalties and delays.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="sigma_service style-1">
                <div className="sigma_service-body">
                  <h5>Voluntary Disclosure</h5>
                  <p>
                    Prompt voluntary disclosure of errors or omissions in import
                    documentation demonstrates commitment to compliance and may
                    mitigate penalties. We assist businesses in navigating
                    voluntary disclosure processes to rectify discrepancies and
                    maintain regulatory compliance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_service style-1">
                <div className="sigma_service-body">
                  <h5>Logistics</h5>
                  <p>
                    Efficient logistics management is critical for timely and
                    cost-effective import operations. Apex offers end-to-end
                    logistics solutions, including transportation, warehousing,
                    inventory management, and distribution, optimizing supply
                    chain performance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_service style-1">
                <div className="sigma_service-body">
                  <h5>Transfer Pricing & Process Solutions</h5>
                  <p>
                    Managing transfer pricing ensures compliance with tax
                    regulations while optimizing financial performance. Apex
                    provides tailored process solutions to streamline transfer
                    pricing mechanisms, enhancing profitability and compliance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="sigma_service style-1">
                <div className="sigma_service-body">
                  <h5>Duty Optimization</h5>
                  <p>
                    Maximizing duty savings through strategic planning and
                    utilization of preferential trade agreements is essential
                    for cost-effective import operations. Apex employs duty
                    optimization strategies, such as tariff engineering and duty
                    drawback, to minimize duty liabilities and enhance
                    competitiveness.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_service style-1">
                <div className="sigma_service-body">
                  <h5>International Supply Chain Network Modeling</h5>
                  <p>
                    Designing an efficient international supply chain network
                    requires sophisticated modeling techniques and strategic
                    planning. Apex utilizes advanced modeling tools to optimize
                    supply chain networks, enhancing agility, resilience, and
                    cost efficiency.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_service style-1">
                <div className="sigma_service-body">
                  <h5>Government Regulations</h5>
                  <p>
                    Staying abreast of evolving government regulations is
                    essential for maintaining compliance and mitigating risks.
                    Apex provides expert guidance on navigating complex
                    regulatory landscapes, ensuring adherence to import laws and
                    regulations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section light-bg">
        <div className="spacer spacer-vertical spacer-xl left bg-white d-none d-lg-block" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 mb-lg-30">
              <img src="assets/img/service/bg1.jpg" alt="img" />
            </div>
            <div className="col-lg-6">
              <div className="mr-lg-30">
                <div className="section-title mb-0 text-left">
                  <p className="subtitle">
                    <span /> About us <span />
                  </p>
                  <h4 className="title">
                    Have any questions about our services?
                  </h4>
                </div>
                <div className="accordion with-gap" id="generalFAQExample">
                  <div className="card">
                    <div
                      className="card-header"
                      data-toggle="collapse"
                      role="button"
                      data-target="#generalOne"
                      aria-expanded="true"
                      aria-controls="generalOne"
                    >
                      How can Apex Product Import Services help me optimize my
                      duty payments?
                    </div>
                    <div
                      id="generalOne"
                      className="collapse show"
                      data-parent="#generalFAQExample"
                    >
                      <div className="card-body">
                        Our duty optimization strategies leverage our expertise
                        in customs regulations and trade agreements to minimize
                        duty payments while ensuring compliance.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      data-toggle="collapse"
                      role="button"
                      data-target="#generalTwo"
                      aria-expanded="false"
                      aria-controls="generalTwo"
                    >
                      What sets Apex Product Import Services apart from other
                      logistics providers?
                    </div>
                    <div
                      id="generalTwo"
                      className="collapse"
                      data-parent="#generalFAQExample"
                    >
                      <div className="card-body">
                        We offer a holistic approach to logistics management,
                        integrating compliance, risk management, and efficiency
                        to deliver seamless end-to-end solutions.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      data-toggle="collapse"
                      role="button"
                      data-target="#generalThree"
                      aria-expanded="false"
                      aria-controls="generalThree"
                    >
                      What types of government regulations do you assist with?
                    </div>
                    <div
                      id="generalThree"
                      className="collapse"
                      data-parent="#generalFAQExample"
                    >
                      <div className="card-body">
                        We provide comprehensive support for navigating a wide
                        range of government regulations, including trade
                        policies, import/export restrictions, and customs
                        procedures.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      data-toggle="collapse"
                      role="button"
                      data-target="#generalSeven"
                      aria-expanded="false"
                      aria-controls="generalSeven"
                    >
                      How do you ensure accuracy in product classification?
                    </div>
                    <div
                      id="generalSeven"
                      className="collapse"
                      data-parent="#generalFAQExample"
                    >
                      <div className="card-body">
                        Our experienced team utilizes advanced classification
                        methodologies and stays updated with the latest tariff
                        schedules to ensure accurate product classification.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      data-toggle="collapse"
                      role="button"
                      data-target="#generalFive"
                      aria-expanded="false"
                      aria-controls="generalFive"
                    >
                      Can Apex Product Import Services help me with customs
                      warehousing needs?
                    </div>
                    <div
                      id="generalFive"
                      className="collapse"
                      data-parent="#generalFAQExample"
                    >
                      <div className="card-body">
                        Yes, our customs warehousing solutions provide secure
                        storage facilities and streamlined customs procedures to
                        optimize inventory management and reduce costs.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
