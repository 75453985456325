import React from "react";
import { Link } from "react-router-dom";

const Faq = () => {
  return (
    <>
      <div
        className="sigma_subheader dark-overlay"
        style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1>FAQ</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  FAQ
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="section-title text-left">
            <p className="subtitle">
              <span />
              Frequently Asked Questions <span />
            </p>
            <h4 className="title">Find answers to questions here</h4>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-lg-30">
              <div className="accordion with-gap" id="generalFAQExample">
                <div className="card">
                  <div
                    className="card-header"
                    data-toggle="collapse"
                    role="button"
                    data-target="#generalOne"
                    aria-expanded="true"
                    aria-controls="generalOne"
                  >
                    How can Apex Product Import Services help me optimize my
                    duty payments?
                  </div>
                  <div
                    id="generalOne"
                    className="collapse show"
                    data-parent="#generalFAQExample"
                  >
                    <div className="card-body">
                      Our duty optimization strategies leverage our expertise in
                      customs regulations and trade agreements to minimize duty
                      payments while ensuring compliance.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header"
                    data-toggle="collapse"
                    role="button"
                    data-target="#generalTwo"
                    aria-expanded="false"
                    aria-controls="generalTwo"
                  >
                    What sets Apex Product Import Services apart from other
                    logistics providers?
                  </div>
                  <div
                    id="generalTwo"
                    className="collapse"
                    data-parent="#generalFAQExample"
                  >
                    <div className="card-body">
                      We offer a holistic approach to logistics management,
                      integrating compliance, risk management, and efficiency to
                      deliver seamless end-to-end solutions.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header"
                    data-toggle="collapse"
                    role="button"
                    data-target="#generalThree"
                    aria-expanded="false"
                    aria-controls="generalThree"
                  >
                    What types of government regulations do you assist with?
                  </div>
                  <div
                    id="generalThree"
                    className="collapse"
                    data-parent="#generalFAQExample"
                  >
                    <div className="card-body">
                      We provide comprehensive support for navigating a wide
                      range of government regulations, including trade policies,
                      import/export restrictions, and customs procedures.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header"
                    data-toggle="collapse"
                    role="button"
                    data-target="#generalSeven"
                    aria-expanded="false"
                    aria-controls="generalSeven"
                  >
                    How do you ensure accuracy in product classification?
                  </div>
                  <div
                    id="generalSeven"
                    className="collapse"
                    data-parent="#generalFAQExample"
                  >
                    <div className="card-body">
                      Our experienced team utilizes advanced classification
                      methodologies and stays updated with the latest tariff
                      schedules to ensure accurate product classification.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header"
                    data-toggle="collapse"
                    role="button"
                    data-target="#generalFive"
                    aria-expanded="false"
                    aria-controls="generalFive"
                  >
                    Can Apex Product Import Services help me with customs
                    warehousing needs?
                  </div>
                  <div
                    id="generalFive"
                    className="collapse"
                    data-parent="#generalFAQExample"
                  >
                    <div className="card-body">
                      Yes, our customs warehousing solutions provide secure
                      storage facilities and streamlined customs procedures to
                      optimize inventory management and reduce costs.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
