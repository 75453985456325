import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendMessage = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await axios.post("https://app.importwithapex.com/api/message", {
        name,
        email,
        phone,
        text: message,
      });

      setLoading(false);
      toast.success("Message sent");
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div
        className="sigma_subheader subheader-lg dark-overlay"
        style={{ backgroundImage: "url(assets/img/subheader.jpg)" }}
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1>Contact Us</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact Us
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="section pt-7">
        <div className="container">
          <div className="section-title text-center">
            <p className="subtitle">
              <span /> Get a Quote <span />
            </p>
            <h4 className="title">Let's work with you</h4>
          </div>
          <div className="row">
            <div className="offset-lg-1 col-lg-10">
              <form
                className="mf_form_validate ajax_submit"
                encType="multipart/form-data"
                onSubmit={sendMessage}
              >
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <i className="far fa-user" />
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="form-control"
                        name="fullname"
                        required
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <i className="far fa-envelope" />
                      <input
                        type="email"
                        placeholder="Email Address"
                        className="form-control"
                        name="email"
                        required
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <i className="far fa-pencil" />
                      <input
                        type="tel"
                        placeholder="Phone"
                        className="form-control"
                        name="phone"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    placeholder="Enter Message"
                    cols={45}
                    rows={5}
                    className="form-control"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </div>
                <div className="text-center">
                  {loading ? (
                    <button
                      type="button"
                      disabled
                      className="sigma_btn-custom secondary"
                      name="button"
                    >
                      Get Consultancy...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="sigma_btn-custom secondary"
                      name="button"
                    >
                      Get Consultancy <i className="far fa-plus" />
                    </button>
                  )}
                  <div className="server_response w-100" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="sigma_map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1982.3678420166639!2d3.467298388736544!3d6.427993741218648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b936f46a4cbb9%3A0x8d20c70089944f78!2sWorkcity%20Africa!5e0!3m2!1sen!2sng!4v1709794535729!5m2!1sen!2sng"
          allowFullScreen
        />
      </div>

      <ToastContainer
        theme="colored"
        autoClose={4000}
        closeButton={false}
        hideProgressBar
        position="bottom-center"
      />
    </>
  );
};

export default Contact;
