import { Link } from "react-router-dom";
import logo from "../assets/logo-dark.png";

const Footer = () => {
  return (
    <footer className="sigma_footer style-3 sigma_footer-dark pattern-squares pb-0">
      <div className="sigma_footer-middle">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="sigma_footer-widget">
                <div className="sigma_footer-logo mb-4">
                  <img src={logo} alt="logo" width={250} />
                </div>
                <h5 className="widget-title">Contact Details</h5>
                <div className="row">
                  <div className="col-sm-9">
                    <p className="m-0">
                      Plot 1, Maruwa Bus Stop, 128 Remi Olowude St, Lekki Phase
                      1, Lagos, Nigeria
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-sm-4">
              <div className="sigma_footer-widget">
                <h5 className="widget-title">Quick Links</h5>
                <ul className="sigma_footer-links">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ's</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sigma_footer-bottom mt-0 d-block d-sm-flex align-items-center justify-content-between">
          <ul className="sigma_sm square mb-3 mb-sm-0">
            <li>
              <a href="#/">
                <i className="fab fa-facebook-f" />
              </a>
            </li>
            <li>
              <a href="#/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style={{ width: "14", height: "14" }}
                >
                  <path
                    fill="#999b9f"
                    d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="#/">
                <i className="fab fa-instagram" />
              </a>
            </li>
          </ul>
          <div className="sigma_footer-copyright m-0">
            <p className="mb-0">
              © Apex Product Import Services -
              <a href="#/">{new Date().getFullYear()}</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
